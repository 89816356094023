<template>
  <div class="container" ref="contact">
      <div class="credits">
        <div class="text">

          <!-- Pre heading -->
          <h2
            v-if="subtitle"
          >{{subtitle}}</h2>

          <!-- The actuall heading -->
          <h3
          v-if="title"
          >{{title}}</h3>

          <!-- The CTA -->
          <div
            v-if="show_link"
          >
            <Link
              :link=link
              class="button-long"
            />
          </div>
          
          <!-- The blerb at the bottom -->
          <div
            class="contact-blurb"
            v-if="contact_blurb"
            v-html="contact_blurb"
          />
        </div>
      </div>
  </div>
</template>

<script>
import Link from '../components/Link'

export default {
  props: {
    contact: Object
  },
  computed: {
    subtitle () {
      return this?.contact?.subtitle || ''
    },
    title () {
      return this?.contact?.title || ''
    },
    contact_blurb () {
      return this?.contact?.contact_blurb || '' 
    },
    link () {
      return (this?.contact?.link && this?.contact?.link !== '')
      ? this?.contact?.link
      : {
        url_relative: '/contact',
        title: `LET'S CHAT`
      }
    },
    show_link () {
      return (this?.contact?.disable_link === true)
        ? false
        : true
    }
  },
  methods: {},
  components: {
    Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";
@import "../styles/buttons";

  .container {
    @extend %container;
    @extend %mobile-container;
    display: flex;
    /* margin-bottom: 220px; */
    @media (max-width: 800px) {
      flex-direction: column-reverse;
      /* margin-top: 124px; */
      /* margin-bottom: 124px; */
    }

    .credits {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: d-grid(10);
      border: 1px solid $red;
      border-radius: 110px;
      margin-left: auto;
      margin-right: auto;
      /* margin-top: d-grid(-0.5); */
      /* margin-bottom: d-grid(1); */
      padding-left: d-grid(1);
      padding-right: d-grid(1);

      @media (max-width: 800px) {
        margin-top: -10px;
        padding: 0px;
        width: calc(100% + 200px);
        max-width: 200%;
        margin-left: -100px;
        margin-bottom: d-grid(1);
      }

      h2 {
        margin: 0px;
        @include b3;
        opacity: 0.6;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 400;
        @media (max-width: 800px) {
          @include m-b3;
        }
      }

      h3 {
        margin: 0px;
        padding-top: 25px;
        @include t2;
        letter-spacing: 0.1em;
        font-weight: 400;
        line-height: 100%;

        @media (max-width: 800px) {
          @include m-t2;
        }
      }

      .button-long {
        margin-top: 25px;
        @extend %btn-outline-red;
        align-self: flex-start;
        cursor: pointer;
        @media (max-width: 800px) {
          @include m-margin(0.5);
          @include m-b3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .text {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: d-grid(1);
        padding-bottom: d-grid(1);
        text-align: center;
        font-size: px(14);
        line-height: px(19);
        white-space: pre-line;
        font-style: normal;
        letter-spacing: .1em;

        @media (max-width: 1100px) {
          font-size: px(12);
          line-height: px(17);
        }
        @media (max-width: 800px) {
          max-width: calc(100vw - 16vw);
          padding-top: 10%;
          padding-bottom: 10%;
        }
      }
    }
  }

  .contact-blurb /deep/ {
    margin-top: 92px;
    font-style: normal;

    @media (max-width: 800px) {
      margin-top: 46px;
    }
    
    p {
      margin: 0px;
      @include m-b2;
    }

    p + p {
      margin-top: 24px;
    }
  }


  @include link-text-hover-underline ('.contact-blurb /deep/ a', px(1));
  @media (max-width: 800px) {
    @include link-text-hover-underline ('.contact-blurb /deep/ a', 0px);
  }
</style>
