<template>
  <div class="container">
    <div class="link-box">
      <Link
        class="cta"
        v-aspect-ratio="1"
        :link=link
      />
      
    </div>
  </div>
</template>

<script>
// import Categories from './Categories'
import Link from './Link'
export default {
  props: {
    link: [Object, String]
  },
  components: {
    // Categories,
    Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/buttons";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;

  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 800px) {
    justify-content: center;
  }

  .link-box {
    width: d-grid(2);
    margin-right: d-grid(1);

    @media (max-width: 800px) {
      width: m-grid(2);
      margin-right: 0px;
    }

    a {
      @extend %btn-circle;
      cursor: pointer;
    }
  }
}

</style>
