<template>
  <router-link
    v-if="link && link.url_relative"
    :to="link.url_relative"
    v-html="link.title"
    @click.native="$emit('native')"
  />
  <a
    v-else-if="link"
    :href="link.url"
    :target="link.target"
    v-html="link.title"
  />
</template>

<script>
// Note the click native is here inorder to help with the
// ServicesOther component which uses this component
export default {
  props: {
    link: Object
  }
}
</script>
