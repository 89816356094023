<template>
<div>
  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader />
    </div>
  </transition>

  <!-- Content. -->
  <div
    v-if="page"
    class="page-box moving-background services"
    ref="services"
    >

    <!-- Main Heading -->
    <div 
      class="inline-container-title"
      v-if="page.title && page.title.rendered"
    >
      <div class="content">
        <h1>{{page.title.rendered}}</h1>
        <h2>services / {{page.title.rendered}}</h2>
      </div>
    </div>

    <!-- Content in red box -->
    <ProjectContent
      class="ProjectContent"
      :project="page" 
    />

    <!-- The CTA on the right -->
    <ServiceCta
      class="ServiceCta"
      :v-if="page.acf.call_to_action"
      :link="page.acf.call_to_action"
    />

    <!-- List of other services -->
    <ServicesOther
      class="ServicesOther"
      v-if="page && page.acf.other_services"
      :other_services="page.acf.other_services"
      :curentMatched="curentMatched"
    />
    
    <!-- Footer -->
    <Contact2021 
      class="Contact2021"
      v-if="page && page.acf.contact && page.acf.contact.contact_blurb && page.acf.contact.subtitle && page.acf.contact.title"
      :contact=" page.acf.contact" 
    />
    <SubscribeForm2021
      class="SubscribeForm2021"
    />
    <CopyrightFooter
      class="CopyrightFooter"
      v-if="page && copyright && copyright !== ''"
      :copyright="copyright"
    />

    <!-- The Video Modal -->
    <VideoModal
      :active="videoModalActive"
      :embed_url="modalVideo.embed_url"
      :height="Number(modalVideo.height)"
      :width="Number(modalVideo.width)"
      @close="videoModalActive = false"
    />

  </div>

</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import Loader from '../components/Loader'
import VideoModal from '../components/VideoModal'
import ProjectContent from '../components/ProjectContent'
import Contact2021 from '../components/Contact2021'
import SubscribeForm2021 from '../components/SubscribeForm2021'
import ServicesOther from '../components/ServicesOther'
import ServiceCta from '../components/ServiceCta'
import CopyrightFooter from '../components/CopyrightFooter'
import throttle from 'lodash/throttle'
import { TweenLite } from 'gsap'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true,
      page: null,
      primaryNavActive: false,
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null,
      videoModalActive: false,
      modalVideo: {
        embed_url: 'https://player.vimeo.com/video/112866269',
        height: 360,
        width: 640
      }
    }
  },
  computed: {
    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    },
    curentMatched () {
      return this.$matchedRoute
    }
  },
  methods: {
    async fetchPage () {
      // console.log(this.$matchedRoute.pathResolved)
      if (this.$route.query.preview) {
        this.page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        this.page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }
      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(this.$refs.services, 30, {
                backgroundPositionY: '0%'
              }, {
                backgroundPositionY: '100%'
              })
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration(document.body.scrollHeight)
        })
      }
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    // This will only run once - we use @click.native="$router.go()" in the child to refresh
    next(vm => vm.fetchPage())
  },
  components: {
    VideoModal,
    ProjectContent,
    Loader,
    Contact2021,
    SubscribeForm2021,
    ServicesOther,
    ServiceCta,
    CopyrightFooter
  }
}
</script>

<style lang="scss" scoped>
  @import "./../styles/theme";
  @import "./../styles/container";
  @import "../styles/text";
  @import "../styles/variables";
  @import "../styles/grid";
  @import '../styles/buttons';

  .loading {
    @include loading;
  }
  @include loading-page-fade;


  .page-box {
    padding-top: 175px;
    padding-bottom: 110px;

    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  .moving-background {
    background-image: url('./../assets/services/bg.svg');
    background-position: top left;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: 268.61px 2502.96px;
    @media (max-width: 800px) {
      background-image: none;
    }
  }


  .inline-container-title {
    @extend %container;
    @extend %mobile-container;
    display: flex;

    .content {
      /* margin-left: #{grid-pct(1)}; */
      width: #{grid-pct(4)};
      @media (max-width: 800px) {
        @include m-margin(0.5);
        margin-bottom: 0px;
        margin-top: 0px;
        width: 100%;
      }

      h1 {
        margin: 0px;
        margin-bottom: 20px;
        @include t2;
        font-weight: 400;
        @media (max-width: 800px) {
           @include m-t2;
        }
      }
      h2 {
        margin: 0px;
        @include b2;
        font-weight: 400px;
        text-transform: uppercase;
        opacity: 0.6;
        font-weight: 400;
        @media (max-width: 800px) {
           @include m-b2;
        }
      }
    }
  }

  .ProjectContent {
    margin-top: 100px;
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }

  .ServiceCta /deep/ .link-box{
    margin-top: d-grid(-0.5);
    margin-bottom: d-grid(-1.5);
    @media (max-width: 800px) { 
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }


  .ServicesOther {
    margin-top: 130px;
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }

  .Contact2021 {
    /* !important resovels an issue with spacing not updating */
    padding-top: 130px;
    @media (max-width: 800px) {
      padding-top: 110px;
    }
  }

  .SubscribeForm2021 {
    margin-top: 60px;
  }
  
  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }

@include link-text-hover-underline('.inline-container-other a', 1px, white, white)

</style>
