<template>
  <div 
      class="inline-container-other" 
    >
      <h2>Other Services</h2>
      
      <div class="content">
          
          <span
            class="service-link"
            v-for="(other, index) in other_services" :key="index"
          > 
            <Link
              :class="{
                disabled: isMatch(other.service_link.url_relative)
              }"
              v-if="other && other.service_link"
              :link="other.service_link"
              @native="$router.go()"
            />
          </span>
      </div>
    </div>
</template>

<script>
import Link from '../components/Link'

export default {

  props: {
    other_services: Array,
    curentMatched: Object
  },
  mounted () {
    // console.log(this.curentMatched)
    // console.log(this.other_services)
  },
  methods: {
    isMatch (otherLink) {
      // If no end slahs we need to add it
      let matched = this?.curentMatched?.pathResolved || ' '
      if (matched.slice(-1) !== '/') {
        matched = matched + '/'
      }
      if (matched === otherLink) {
        return true
      }
      return false
    }
  },
  components: {
    Link
  }
}
</script>

<style lang="scss" scoped>

  @import "./../styles/theme";
  @import "./../styles/container";
  @import "../styles/text";
  @import "../styles/variables";
  @import "../styles/grid";
   @import "../styles/buttons";


  /**8
  * Set the Text Color for the link 
  * Because we can't do the hover and the theme transition with the same transition 
  */
  @include theme('dark') { 
    .inline-container-other .service-link {
      color: white;
      @include theme-transition; 
      a {
        color: inherit;
      } 
    }
  }
  @include theme('light') { 
    .inline-container-other .service-link {
      color: black;
      @include theme-transition; 
      a {
        color: inherit;
      } 
    }
  }

  .inline-container-other {
    @extend %container;
    @extend %mobile-container;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 130px; */
    /* margin-bottom: 130px; */

    @media (max-width: 800px) {
      /* margin-top: 100px; */
    }

    h2 {
      @include t3;
      margin-top: 0px;
      margin-bottom: 40px;
      font-weight: 400;
      @media (max-width: 800px) {
        @include m-t3;
      }
    }

    .content {
      width: d-grid(5, -26px);
      display: flex;
      justify-content: center;
      
      flex-wrap: wrap;

      @media (max-width: 800px) {
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
      }

      .service-link { 
        display: block;
        flex-shrink: 0;
        margin-left: 13px;
        margin-right: 13px;

        letter-spacing: 0.1em;
        text-transform: uppercase;
        @include b3;
        line-height: px(32);

        @media (max-width: 800px) {
          @include m-b3;
          line-height: px(24);
        }

        a {
          opacity: 0.5;
          border-bottom: 1px solid;
          text-decoration: none;
          @include transition-opacity-button;

          // When not curent page we should run the opacity
          &:hover:not(.disabled) {
            opacity: 1;
          }

          // When current page we should be fully solid
          &.disabled {
            border-bottom: 0px solid;
            opacity: 1;
            cursor: default;
            &:hover {
              opacity: 1;
              cursor: default;
            }
          }
        }
      }
    
    }

  }



</style>
